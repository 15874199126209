import { forwardRef } from 'react';
import { IconWrapper } from '../IconWrapper';

export const SVGBack = forwardRef((props, ref) => {
  return (
    <IconWrapper ref={ref} data-component={'svg-back'} {...props} viewBox={'0 0 16 16'}>
      <g stroke={'currentColor'} fill={'none'}>
        <path
          d={'M0.332031 7H15.6654V11.6667'}  strokeLinecap={'round'}
          strokeLinejoin={'round'}
        />
        <path
          d={'M3.66536 10.3327L0.332031 6.99935L3.66536 3.66602'} strokeLinecap={'round'}
          strokeLinejoin={'round'}
        />
      </g>
    </IconWrapper>
  );
});
