import { Box } from '@mui/joy';

export const Logo = ({
  image,
  account_organisation_name,
  sx: propsSx
}) => {
  const sx = {
    maxHeight: 100,
    maxWidth: '100%',
    ...propsSx,
  };

  return (
    <Box
      data-component={'logo'}
      component={'img'}
      src={image}
      alt={account_organisation_name}
      sx={sx}
    />
  );
};
