import { forwardRef } from 'react';
import { IconWrapper } from '../IconWrapper';

export const SVGSave = forwardRef((props, ref) => {
  return (
    <IconWrapper ref={ref} data-component={'svg-save'} {...props} viewBox={'0 0 16 16'}>
      <g stroke={'currentColor'} fill={'none'}>
        <path d={'M15.6654 0.999919C15.6654 0.823108 15.5951 0.653538 15.4701 0.528514C15.3451 0.40349 15.1755 0.333252 14.9987 0.333252H2.60803C2.43123 0.33329 2.26169 0.403552 2.1367 0.528585L0.527365 2.13792C0.402332 2.26291 0.332069 2.43246 0.332031 2.60925V14.9999C0.332031 15.1767 0.402269 15.3463 0.527293 15.4713C0.652318 15.5963 0.821887 15.6666 0.998698 15.6666H14.9987C15.1755 15.6666 15.3451 15.5963 15.4701 15.4713C15.5951 15.3463 15.6654 15.1767 15.6654 14.9999V0.999919Z'} strokeLinecap={'round'} strokeLinejoin={'round'} />
        <path d={'M13 4.33325C13 4.51006 12.9298 4.67963 12.8047 4.80466C12.6797 4.92968 12.5101 4.99992 12.3333 4.99992H3.66667C3.48986 4.99992 3.32029 4.92968 3.19526 4.80466C3.07024 4.67963 3 4.51006 3 4.33325V0.333252H13V4.33325Z'} strokeLinecap={'round'} strokeLinejoin={'round'} />
        <path d={'M3 15.6667H13V13.6667C13 13.4899 12.9298 13.3203 12.8047 13.1953C12.6797 13.0702 12.5101 13 12.3333 13H3.66667C3.48986 13 3.32029 13.0702 3.19526 13.1953C3.07024 13.3203 3 13.4899 3 13.6667V15.6667Z'} strokeLinecap={'round'} strokeLinejoin={'round'} />
        <path d={'M8 11C9.10457 11 10 10.1046 10 9C10 7.89543 9.10457 7 8 7C6.89543 7 6 7.89543 6 9C6 10.1046 6.89543 11 8 11Z'} strokeLinecap={'round'} strokeLinejoin={'round'} />
      </g>
    </IconWrapper>
  );
});
